.blog-topic-card{
    position: relative;
    width: 100%;
    filter: drop-shadow(0px 0px 4px aqua);
    text-decoration: none;
    overflow: hidden;
    border-radius: 10px;
    border: 2px solid aqua;
    color: aliceblue;
    background-color: #282828;
    cursor: pointer;
    transition-duration: 0.6s;
    flex: 1 1 350px;
    opacity: 0.7;
}

.blog-topic-card:hover{
    filter: drop-shadow(0px 0px 4px purple);
    border: 2px solid purple;
    transform: translateY(-10px);
    opacity: 1;
}

.blog-topic-card img{
    width: 100%;
    max-height: 150px;
    object-fit: cover;
}

.card-topic-title{
    margin: 0;
    box-sizing: border-box;
    text-align: left;
    padding-left: 10px;
    filter: drop-shadow(0px 0px 4px #282828);
    width: 100%;
}
.card-topic-date{
    text-align: left;
    margin: 0;
    margin-top: 5px;
    box-sizing: border-box;
    padding-left: 10px;
    filter: drop-shadow(0px 0px 4px #282828);
    width: 100%;
    border-bottom: 2px solid purple;
    padding-bottom: 5px;
}
.card-topic-info{
    margin: 0;
    text-align: left;
    box-sizing: border-box;
    padding: 10px;
    filter: drop-shadow(0px 0px 4px #282828);
    width: 100%;
}

.card-topic-views{
    display: flex;
    align-items: center;
    gap: 5px;
    box-sizing: border-box;
    padding: 5px 0px 0px 10px;
    margin: 0px;
}