@import url('../Animations.css');

.say-hello{
    box-sizing: border-box;
    border-top: 2px solid purple;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;

}
.say-hello.hidden{
    display: none;
}
.input-fields{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: stretch;
    width: 95%;
    max-width: 400px;
    border-radius: 20px;
    padding: 5px;
    border-bottom: 2px solid purple;
}
.input-fields label{
    width: max-content;
    margin-left: 5px;
    margin-top: 10px;
    font-weight: bold;
}
.input-fields input{
    background-color: #585858;
    border: 2px #383838 solid;
    color: white;
    font-size: 16px;
    padding: 5px;
    border-radius: 10px;
}
.input-fields input:focus{
    outline: none;
}
.say-hello button{
    border:none;
    padding: 5px;
    display: flex;
    align-items: center;
    align-self: center;
    text-align: center;
    margin-top: 10px;
    border-radius: 10px;
    color: #151515;
    background-color: #585858;
    transition-duration: 0.6s;
    height: 0px;
    margin-bottom: 7px;
    overflow: hidden;
}
.say-hello button:disabled{
    color: #585858;
    font-size: 16px;
    height: 0px;
}
.say-hello button:enabled img{
    width: 30px;
}
.say-hello button:enabled{
    height: 45px;
}
.say-hello button img{
    width: 0px;
    object-fit: cover;
    transition-duration: 0.6s;
    animation: wave 2s linear infinite;
}
.say-hello button:enabled:hover{
    background-color: rgba(128, 0, 128, 0.6);
    cursor: pointer;
    color: white;
}

.say-hello button:enabled:hover img{
    width: 40px;
}

.contact-social{
    width: 95%;
    max-width: 600px;
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
    padding-bottom: 40px;
    font-size: 20px;
    text-align: center;
}
.contact-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}