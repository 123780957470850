@import url('Animations.css');

.app{
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: aqua;
}

/*=================================================================== */

footer{
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-top: 2px solid aqua;
	width: 100%;
	height: fit-content;
}

.name-logo{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	height: fit-content;
}

.footer-logo{
	width: 80px;
	object-fit: contain;
}

.footer-name{
	display: flex;
	border-bottom: 3px solid purple;
	border-radius: 30%;
	padding: 10px;
}
.footer-name span{
	font-size: smaller;
}
.footer-links{
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 10px;
	width: 80%;
}

.social-media-links{
	display: flex;
	flex-direction: column;
	width: fit-content;
}

.social-icon{
	text-decoration: none;
	display: flex;
	flex-direction: row;
	align-items: baseline;
	font-size: 0px;
	transition-duration: 0.7s;
	width: 150px;
	padding-right: 10px;
	border-bottom: 4px solid transparent;
	border-bottom-left-radius: 35px;
	color: aliceblue;
}

.social-icon:hover{
	font-size: large;
	cursor: pointer;
	border-bottom: 4px solid purple;
	
}

.social-icon:hover img{
	border: 4px solid purple;
	animation: none;
}

.social-media-links img{
	width: 30px;
	padding: 5px;
	transition-duration: 0.7s;
	border-radius: 30px;
	border: 3px solid transparent;
	animation: iconPulse 3s linear infinite;
}

.footer-link-list{
	list-style-type: none;
	text-align: left;
}
.footer-link-list li{
	background: url('./Resources/link.svg') left center no-repeat;
	padding: 5px;
	padding-left: 35px;
	margin: 5px;
	border-radius: 20px;
	border-top-right-radius: none;
	border-bottom-right-radius: none;
	border: solid 2px transparent;
	border-right: none;
	transition-duration: 0.2s;
	width: max-content;
	height: 20px;
}

.footer-link-list li a{
	text-decoration: none;
	color: aqua;
	transition-duration: 0.2s;
}

.footer-link-list li:hover a{
	text-decoration: none;
	color: purple;
	
}

.footer-link-list li:hover{
	text-decoration: none;
	border-color: aqua;
	font-size: larger;
	cursor: pointer;
}

