.blog{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    width: 100%;
    margin-bottom: 10px;
}
.blog h1{
    margin-left: 40px;
    font-weight: bold;
    justify-self: left;
    width: max-content;
    text-align: center;
    text-wrap: wrap;
}
.blog-topics{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

@media (min-width: 1025px) and (max-width: 1400px) {
    .blog h1{
        margin-left: 40px;
        font-size: 30px;
    }
}

@media (min-width: 700px) and (max-width: 1024px) {
    .search-bar-section{
        margin-left: 10px;
    }
    .blog h1{
        margin-left: 10px;
        font-size: 24px;
    }
    .keyword-container button{
        font-size: 14px;
    }
}

@media (min-width: 300px) and (max-width: 699px) {
    .search-bar-section{
        margin-left: 5px;
    }
    .blog h1{
        margin-left: 5px;
        font-size: 22px;
    }
    .keyword-container button{
        font-size: 14px;
    }
}

@media (max-width: 299px) {
    .search-bar-section{
        margin-left: 0px;
    }
    .blog h1{
        margin-left: 0px;
        font-size: 16px;
    }
    .keyword-container button{
        font-size: 14px;
    }
}
