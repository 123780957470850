@import url('../Animations.css');

.home{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url('../Resources/HomeBackground.jpg');
    background-size: 25%;
}


.home-content{
    overflow: hidden;
    width: 90%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #202020;
    padding-bottom: 20px;
    filter: drop-shadow(0px 0px 4px aqua);
    border-radius: 20px;
    color: aliceblue;
    text-align: center;
}

.home-heading-container{
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

#home-avatar{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    width: 90%;
    max-width: 280px;
    height: 280px;
    transition-duration: 2s;
    overflow: hidden;
    filter: drop-shadow(0px 0px 4px white);
    border-radius: 50% 70% 50% 90%;
    object-fit: cover;
}

.hello{
    box-sizing: border-box;
    width: 95%;
    max-width: 400px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    text-align: center;
    padding: 10px;
    padding-top: 20px;

}

.name{
    margin-left: 10px;
    color: transparent;
    font-weight: 700;
    background: linear-gradient(to left, purple, aqua);
    -webkit-background-clip: text;
    font-size: 30px;
    text-align: center;
    white-space: nowrap;
}

.name::before {
    content: "Maric, "; 
    animation: changeContent 6s linear forwards;
}

.home .p{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 30px;
    margin: 2px;
    z-index: 1;
    border-radius: 20px;
    margin: 0;
}
.home ul{
    align-self: center;
}
.ul-title{
    padding-left: 10px;
    align-self: center;
}
.home ul li{
    padding: 10px;
}
.home ul li a{
    text-decoration: none;
    color: aliceblue;
    border-bottom: 2px solid purple;
}

.start-page{
    height: 100px;
    background: linear-gradient(to bottom, #141414, transparent);
    width: 100%;
}

.end-page{
    height: 100px;
    background: linear-gradient(to top, #141414, transparent);
    width: 100%;
}

.home-content-heading{
    box-sizing: border-box;
    margin: 0;
    margin-bottom: -10px;
    width: 100%;
    max-width: 450px;
    text-align: center;
    padding: 20px;
    color: white;
    background-color: #303030;
    filter: drop-shadow(0px 0px 4px aqua);
    border: 2px solid aqua;
}
.latest-blog-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 450px;
    margin-bottom: 30px;
}

.popular-blogs-container{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;
    padding: 30px 10px;
    gap: 10px;
}

.glimpse-div{
    box-sizing: border-box;
    padding: 10px 10px 30px 10px;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 30px 0px 0px 0px;
    border-bottom: 2px solid purple;
}

.home-glimpse{
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    max-width: 300px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: white;
    border-right: 2px solid purple;
    border-left: 2px solid purple;
    transition-duration: 0.5s;
}

.home-glimpse:hover{
    backdrop-filter: blur(4px);
    filter: drop-shadow(0px 0px 4px white)
}

.home-glimpse p{
    margin: 0;
}







