.skill-list-wraper{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 250px;
    min-height: 250px;
    filter: drop-shadow(0px 0px 4px white);
    overflow: hidden;
}

.skill-list{
    box-sizing: border-box;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.middle-item{
    position: absolute;
    width: 60px;
    animation: fadeIn 1s linear forwards;
}

.middle-text{
    box-sizing: border-box;
    position: absolute;
    text-align: center;
    padding: 10px;
    background-color: #282828;
    border-radius: 14px;
    filter: drop-shadow(0px 0px 4px white);
    max-width: 150px;
    color: white;
    animation: fadeIn 1s linear forwards;
}

@keyframes fadeIn{
    from{
        opacity: 0;
    }to{
        opacity: 1;
    }
}

