.deezer-now-playing-card{
    margin-top: 20px;
    display: flex;
    align-items: center;
    width: 400px;
    overflow: hidden;
    text-decoration: none;
    color: white;
    border: 4px solid purple;
    border-radius: 15px;
    filter: drop-shadow(0px 0px 10px purple);
    transition-duration: 0.6s;
    background: linear-gradient(to right, #343434, transparent);
}
.thumbnail{
    width: 35%;
    object-fit: cover;
    justify-self: flex-start;
    align-self: flex-start;
    border-right: 2px solid purple;
}
.card-info-container{
    box-sizing: border-box;
    padding: 5px;
    font-size: 13px;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-wrap: wrap;
    
}
.card-info-container h3{
    box-sizing: border-box;
    margin: 0;
    padding: 5px;
}
.card-info-container p{
    box-sizing: border-box;
    margin: 0;
    padding: 5px;
}
.deezer-logo{
    position: absolute;
    width: 40px;
    align-self: flex-start;
    padding: 5px;
    border-radius: 15px;
}
.deezer-now-playing-card:hover{
    background-color: transparent;
    border-color: aqua;
    filter: drop-shadow(0px 0px 10px aqua);
}

@media (min-width: 1025px) and (max-width: 1400px) {
    
}

@media (min-width: 700px) and (max-width: 1024px) {
    .deezer-now-playing-card{
        width: 50%;
        font-size: 14px;
    }
}

@media (min-width: 300px) and (max-width: 699px) {
    .deezer-now-playing-card{
        width: 95%;
        font-size: 14px;
    }
}

@media (max-width: 299px) {
    .deezer-now-playing-card{
        flex-direction: column;
        width: 90%;
        font-size: 12px;
        height: fit-content;
    }
    .thumbnail{
        width: 100%;
        height: 100px;
        border: none
    }
    .card-info-container{
        background: linear-gradient(to bottom, #343434, transparent);
    }
}

