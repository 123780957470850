@import url('../Animations.css');

.AboutMe{
    position: relative;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: max-content;
    padding: 10px;
}

.info{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-evenly;
}

.AboutMe h1{
    color: aqua;
}

.avatarSec{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 100%;
    gap: 20px;
    z-index: 3;
    filter: drop-shadow(0px 0px 4px #141414);
}

.stats{
    max-width: 95%;
    filter: drop-shadow(0px 4px 4px black);
}

#Avatar{
    width: 95%;
    max-width: 300px;
    clip-path: circle();
    object-fit:scale-down;
    border-radius: 100%;
    border: 5px solid #141414;
}

.about-divider{
    box-sizing: border-box;
    width: 100%;
    min-height: 80px;
    background: linear-gradient(to top, #282828, transparent);
}

.mainInfo{
    box-sizing: border-box;
    width: 100%;
    padding: 40px 10px 10px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-evenly;
    height: max-content;
    background-color: #282828;
    color: white;
    z-index: 0;
}

.bio-sec{
    box-sizing: border-box;
    display: flex;
    flex-direction: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
}

#CityLink{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    text-decoration: none;
    color: aqua;
    transition-duration: 0.8s;
    width: 95%;
    max-width: 400px;
    height: 80px;
    border-bottom: 2px solid transparent;
}

#CityLink:hover{
    color: white;
    font-size: 0px;
    border-bottom: 2px solid purple;
    border-radius: 50px;
}

#CityLink #planet{
    width: 80px;
    transition-duration: 1s;
}

#CityLink:hover #planet{
    transform: rotate(360deg);
}

.bio-sec p{
    text-align: center;
    max-width: 500px;
}

.bio-sec p:first-child{
    text-align: center;
    width: 100%;
    max-width: 100%;
}

.bio-sec p:nth-child(3){
    text-align: center;
    width: 100%;
    max-width: 100%;
}

.bio-sec h3{
    width: 100%;
    text-align: center;
}

.certificates-sec{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    gap: 10px;
}

.certificates-sec h1{
    width: 100%;
    text-align: center;
}

.lang-buttons{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;
    max-width: 200px;
    padding: 10px;
}
.lang-buttons button{
    text-align: left;
    width: 100%;
    padding: 10px;
    background: none;
    border: none;
    border: 2px white solid;
    color: aqua;
    transition-duration: 0.4s;
    border-radius: 10px 0px 0px 10px;
}
.lang-buttons button:hover{
    background-color: rgba(128, 0, 128, 0.5);
    border: 2px purple solid;
    cursor: pointer;
}
.lang-buttons button.selected{
    background-color: rgba(128, 0, 128, 0.5);
    border: 2px purple solid;
    transform: translateX(25px);
    filter: drop-shadow(0px 0px 10px purple);
}

@media (min-width: 280px) and(max-width: 800px) {
    .certificates-sec{
        gap: 0px;
    }
    .lang-buttons{
        flex-direction: row;
    }
    .lang-buttons button{
        text-align: center;
        width: fit-content;
        border-radius: 10px 10px 0px 0px;
    }
    .lang-buttons button.selected{
        background-color: rgba(128, 0, 128, 0.5);
        border: 2px purple solid;
        transform: translateY(25px);
        filter: drop-shadow(0px 0px 10px purple);
    }
}

@media (max-width: 280px){
    .certificates-sec{
        gap: 0px;
    }
    .lang-buttons{
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        height: 100px;
    }
    .lang-buttons button{
        box-sizing: border-box;
        text-align: center;
        max-width: 45%;
        border-radius: 10px;
    }
    .lang-buttons button.selected{
        background-color: rgba(128, 0, 128, 0.5);
        border: 2px purple solid;
        transform: translateY(0px);
        filter: drop-shadow(0px 0px 10px purple);
        border-radius: none;
    }
}

.hidden{
    display: none;
}