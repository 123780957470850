.background-canvas-wraper{
    width: 100%;
    height: 100%;
    position: absolute;
    filter: drop-shadow(0px 0px 4px white);
    top: 0;
    left: 0;
}

.background-canvas{
    border: none;
    width: 100%;
    height: 100%;
}