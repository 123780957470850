.burger-button{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 40px;
    height: 40px;
    cursor: pointer;
    padding: 2px;
    margin: 10px 0px 20px 10px;
    background-color: transparent;
    border: 2px solid purple;
    border-radius: 5px;
}

.burger-button-inactive{
    display: none;
}

.burger-button .line{
    width: 100%;
    background-color: aqua;
    height: 2px;
    transition-duration: 0.6s;
}

.burger-button .line.inactive{
    display: none;
}

.burger-button .line.rotate{
    position: absolute;
    transform: rotate(45deg);
}

.burger-button .line.rotate:last-child{
    position: absolute;
    transform: rotate(-45deg);
}