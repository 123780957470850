.project-card{
    color: white;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    min-height: 400px;
    max-height: 400px;
    border-radius: 10px;
    overflow: hidden;
    border: 2px solid rgba(0, 255, 255, 0.3);
    cursor: pointer;
    animation: popInPC 1.5s ease forwards;
    transition-duration: 1s;
}


@keyframes popInPC {
    0% { max-width: 0px;}
    60% { max-width: 320px; } /* Overshoot the width slightly */
    100% { max-width: 300px;}
}

.project-cover{
    min-height: 100%;
    object-fit: cover;
}

.project-card:hover .project-info{
    opacity: 1;
}

.project-info{
    opacity: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    box-sizing: border-box;
    padding: 0px 10px 10px 10px;
    width: 100%;
    background-color: rgba(40, 40, 40, 0.5);
    backdrop-filter: blur(5px);
    position: absolute;
    bottom: 0;
    transition-duration: 0.6s;
}

.project-info h2{
    margin: 2px;
}

.project-info h3{
    margin: 2px;
}

.project-tech-list{
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
}

.project-tech-list img{
    width: 30px;
}

.project-info a{
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    gap: 5px;
    transition-duration: 0.6s;
}

.project-info a:hover{
    color: purple;
}

.hover-popup{
    box-sizing: border-box;
    position: absolute;
    top: 0;
    margin: 0;
    padding: 10px;
    animation: pop 3s linear infinite;
    background-color: rgba(0, 255, 255, 0.4);
    width: 100%;
    text-align: center;
}

.project-card:hover .hover-popup{
    display: none;
}

@keyframes pop {
    0%{ color: transparent;}
    30%{color: transparent;}
    50%{ color: white;}
    100% { color: transparent;}
}

@media (max-width: 699px) {
    .project-card{
        max-height: max-content;
    }
    .project-info{
        opacity: 1;
    }
    .hover-popup{
        opacity: 0;
    }
    .project-tech-list .techItem{
        width: 30px;
    }
    .project-tech-list img{
        width: 20px;
    }
}