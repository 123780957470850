.loading-card {
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 0px;
    height: 100%;
    min-height: 300px;
    border-radius: 20px;
    border: 2px solid rgba(0, 255, 255, 0.349);
    overflow: hidden;
    animation: popIn 2s ease infinite;
}

.loading-card:nth-child(1){
    animation-delay: 0s;
}
.loading-card:nth-child(2){
    animation-delay: 0.25s;
}
.loading-card:nth-child(3){
    animation-delay: 0.5s;
}
.loading-card:nth-child(4){
    animation-delay: 0.75s;
}

@keyframes popIn {
    0% { max-width: 200px;}
    30% { max-width: 260px; } /* Overshoot the width slightly */
    80% { max-width: 240px;}
    100% { max-width: 200px; } /* Set the final width */
}

.inner-div {
    box-sizing: border-box;
    width: 90%;
    min-height: 270px;
    border-radius: 10px;
    filter: drop-shadow(0px 0px 10px aqua);
    background-color: rgba(0, 255, 255, 0.217);
    backdrop-filter: blur(6px);
    transition-duration: 1s;
    animation: load 3s linear infinite;
}

@keyframes load {
    0% {
        backdrop-filter: blur(5px);
        background-color: rgba(0, 255, 255, 0.2);
    }
    50% {
        backdrop-filter: blur(0px);
        background-color: transparent;
    }
    100% {
        backdrop-filter: blur(5px);
        background-color: rgba(0, 255, 255, 0.2);
    }
}


.loading-logo{
    position: absolute;
    width: 50%;
}

