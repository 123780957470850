@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200&display=swap');

.blog-topic-page{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background: url("../Resources/HomeBackground.jpg");
    border-radius: 20px;
    border: 2px purple solid;
    margin-bottom: 20px;
    color:azure;
    overflow: hidden;
}

.blog-topic-title{
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    overflow: hidden;
    background-color: rgba(128, 0, 128, 0.3);
    backdrop-filter: blur(5px);
    box-shadow: 0px 0px 4px white;
}

.blog-topic-title-text{
    box-sizing: border-box;
    width: 100%;
    max-width: 400px;
    position: relative;
    top: 0px;
    z-index: 1;
    padding-left: 10px;
    text-shadow: 3px 3px 2px black;
}

.topic-page-views{
    display: flex;
    align-items: center;
    gap: 5px
}

.page-cover{
    box-sizing: border-box;
    object-fit: cover;
    width: 60%;
    justify-self: center;
}
@media (max-width: 699px) {
    .page-cover{
        width: 100%;
    }
}

.blog-contents{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
}

.blog-topic-section{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    max-width: 700px;
    padding-left: 10px;
    padding-bottom: 10px;
}

.blog-topic-section img{
    width: 95%;
    max-width: 800px;
    object-fit: cover;
}

.code-header {
    box-sizing: border-box;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 10px 0px 10px;
}

.code-header h3{
    margin: 0;
}
  
.copy-button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
}

.copy-button img{
    width: 30px;
}
  

.copy-button:hover img{
    width: 30px;
    filter: drop-shadow(0px 0px 4px white);
}

.blog-topic-page pre{
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    padding: 0;
    color:#eee;
    background-color: rgba(34, 34, 34, 0.8);
    border: 1px solid white;
    width: 95%;
    scrollbar-width:thin;
    scrollbar-color: #888 #eee;
    font-size: 14px;
    border-radius: 10px;
}

.blog-topic-page .inner-pre{
    width: 100%;
    border-radius: 0px 0px 10px 10px;
    margin-bottom: 0;
    border: none;
    border-top: 2px solid white;
}

.blog-topic-page pre::-webkit-scrollbar {
    height: 4px;
    border-radius: 4px;
}

.blog-topic-page pre::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
}
.blog-topic-page pre::-webkit-scrollbar-thumb:hover {
    background-color: #eee;
}
.blog-topic-page a{
    text-decoration: none;
    border-bottom: purple 2px solid;
    color: aqua;
    transition-duration: 0.6s;
}
.blog-topic-page a:hover{
    color:white;
}

@media (min-width: 1025px) and (max-width: 1400px) {
    .blog-topic-title-text h1{
        font-size: 28px;
    }
    .blog-topic-title-text h2{
        font-size: 24px;
    }
    .blog-topic-title-text h3{
        font-size: 20px;
    }
    .paragraph{
        font-size: 16px;
    }
}

@media (min-width: 700px) and (max-width: 1024px) {
    .blog-topic-title-text h1{
        font-size: 22px;
    }
    .blog-topic-title-text h2{
        font-size: 20px;
    }
    .blog-topic-title-text h3{
        font-size: 18px;
    }
    .blog-topic-section{
        width: 80%;
    }
    .paragraph{
        font-size: 16px;
    }
}

@media (min-width: 300px) and (max-width: 699px) {
    .blog-topic-title-text h1{
        font-size: 18px;
    }
    .blog-topic-title-text h2{
        font-size: 16px;
    }
    .blog-topic-title-text h3{
        font-size: 14px;
    }
    .blog-topic-section{
        width: 80%;
    }   
    .paragraph{
        font-size: 14px;
    }
}

@media (max-width: 299px) {
    .blog-topic-title-text h1{
        font-size: 16px;
    }
    .blog-topic-title-text h2{
        font-size: 14px;
    }
    .blog-topic-title-text h3{
        font-size: 14px;
    }
    .paragraph{
        font-size: 12px;
    }
}