.skill-item{
    position: absolute;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 28px;
    min-height: 28px;
    margin: 0;
    padding: 2px;
}

.skill-item:hover{
    min-width: 30px;
    min-height: 30px;
    background-color: transparent;
}

.skill-item:hover img{
    width: 0px;
}

.skill-item img{
    position: absolute;
    width: 98%;
    transition-duration: 0.6s;
}